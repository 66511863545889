import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { API } from "aws-amplify";
import Scrollbars from "react-custom-scrollbars-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleMinus,
  faEye,
  faEyeSlash,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import EmailBuilder from "../../../components/EmailBuilder/EmailBuilder";
import { getCampaignEmailRetryTemplate } from "../../../graphql/queries";

const RetriesTab = ({
  retries,
  handleRetryChange,
  handleDeleteRetry,
  onAddRetry,
  emailTemplates,
  generalTabContent,
}) => {
  const retryEditorRefs = useRef([]);
  const [daysInput, setDaysInput] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [retryContents, setRetryContents] = useState({});
  const [visibleEmailBuilderIndex, setVisibleEmailBuilderIndex] =
    useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    isError: false,
  });

  useEffect(() => {
    console.log("Current retryContents state:", retryContents);
  }, [retryContents]);

  useEffect(() => {
    const newContents = {};
    retries.forEach((retry, index) => {
      newContents[index] =
        retryContents[index] ||
        (retry.template?.name === "Current Template"
          ? generalTabContent
          : retry.template?.body);
    });
    setRetryContents(newContents);
  }, [retries, generalTabContent]);

  const createRetryEmailTemplate = async (index) => {
    const currentRetry = retries[index];

    const params = {
      unit: "day",
      value: currentRetry.days,
      message: generalTabContent,
    };

    try {
      const response = await API.graphql({
        query: getCampaignEmailRetryTemplate,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const responseBody = JSON.parse(
        response.data.getCampaignEmailRetryTemplate.body
      );

      handleRetryChange(index, "template", {
        name: currentRetry.template.name,
        body: responseBody.data,
        message: responseBody.data,
      });

      setRetryContents((prev) => ({
        ...prev,
        [index]: responseBody.data,
      }));

      return responseBody.data;
    } catch (error) {
      console.error("Error generating retry template:", error);
      setSnackbar({
        open: true,
        message: "Error creating retry template",
        isError: true,
      });
      throw error;
    }
  };

  const handleEditorLoad = (editor, index) => {
    console.log("🚀 Editor loading for retry", index);

    if (!retryEditorRefs.current) {
      retryEditorRefs.current = [];
    }

    retryEditorRefs.current[index] = {
      editor,
      exportHtml: () =>
        new Promise((resolve) => {
          editor.exportHtml((data) => resolve(data.html));
        }),
    };

    const currentRetry = retries[index];
    const content =
      retryContents[index] ||
      (currentRetry?.template?.name === "Current Template"
        ? generalTabContent
        : currentRetry?.template?.body);

    if (content) {
      const design = {
        body: {
          rows: [
            {
              cells: [1],
              columns: [
                {
                  contents: [
                    {
                      type: "text",
                      values: {
                        text: content,
                        textAlign: "left",
                        lineHeight: "140%",
                        fontSize: "14px",
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      };

      setTimeout(() => {
        if (editor && editor.loadDesign) {
          editor.loadDesign(design);
        }
      }, 100);
    }

    editor.addEventListener("design:updated", () => {
      editor.exportHtml((data) => {
        const newContent = data.html;
        setRetryContents((prev) => ({
          ...prev,
          [index]: newContent,
        }));
        handleRetryChange(index, "body", newContent);
      });
    });
  };

  const handleRetryContentChange = (index, editor) => {
    if (editor) {
      editor.exportHtml((data) => {
        setRetryContents((prev) => ({
          ...prev,
          [index]: data.html,
        }));
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleAddRetryClick = async (days, index) => {
    if (!days) {
      setSnackbar({
        open: true,
        message: "Please add a value in Days input",
        isError: true,
      });
      return;
    }

    setOpenModal(false);

    try {
      await onAddRetry(days, index);
    } catch (error) {
      console.error("Error adding retry:", error);
      setSnackbar({
        open: true,
        message: "Error creating retry template",
        isError: true,
      });
    }
  };

  const handleRetryDelete = (index) => {
    console.log("🗑️ Deleting retry at index", index);
    console.log("📦 RetryContents before deletion:", retryContents);

    setRetryContents((prev) => {
      const newContents = { ...prev };
      delete newContents[index];
      Object.keys(newContents).forEach((key) => {
        if (Number(key) > index) {
          newContents[Number(key) - 1] = newContents[key];
          delete newContents[key];
        }
      });
      return newContents;
    });

    handleDeleteRetry(index);
  };

  const handleTemplateSelect = (index, selectedTemplate) => {
    const templateObject = {
      name: selectedTemplate.name || selectedTemplate,
      body:
        selectedTemplate.body ||
        (selectedTemplate === "Current Template"
          ? generalTabContent
          : selectedTemplate.body),
    };

    handleRetryChange(index, "template", templateObject);

    const newContent =
      templateObject.name === "Current Template"
        ? generalTabContent
        : templateObject.body;

    setRetryContents((prev) => ({
      ...prev,
      [index]: newContent,
    }));
  };

  const handleGenerateRetry = async (index) => {
    const currentRetry = retries[index];

    if (!currentRetry.days) {
      setSnackbar({
        open: true,
        message: "Please add a value in Days input",
        isError: true,
      });
      return;
    }

    try {
      await createRetryEmailTemplate(index);
      setSnackbar({
        open: true,
        message: "Retry template generated successfully",
        isError: false,
      });
    } catch (error) {
      console.error("Error generating retry template:", error);
      setSnackbar({
        open: true,
        message: "Error generating retry template",
        isError: true,
      });
    }
  };

  const handleEyeButtonClick = (index) => {
    setVisibleEmailBuilderIndex(
      visibleEmailBuilderIndex === index ? null : index
    );
  };

  useEffect(() => {
    return () => {
      retryEditorRefs.current.forEach((ref) => {
        if (ref?.editor) {
          ref.editor.removeEventListener("design:updated");
        }
      });
    };
  }, []);

  return (
    <Box sx={{ p: 2, height: "100%", overflow: "auto" }}>
      <Scrollbars style={{ height: "520px", width: "100%" }}>
        {retries.map((retry, index) => (
          <Box
            key={index}
            sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}
          >
            {retry.template.name === "Current Template" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1" fontWeight="bold">
                  Current Email Template
                </Typography>
                <IconButton
                  onClick={() => handleEyeButtonClick(index)}
                  sx={{ color: "#015d86", marginRight: "27px" }}
                >
                  <Tooltip
                    title={
                      visibleEmailBuilderIndex === index
                        ? "Hide template"
                        : "See template"
                    }
                  >
                    <FontAwesomeIcon
                      icon={
                        visibleEmailBuilderIndex === index ? faEyeSlash : faEye
                      }
                    />
                  </Tooltip>
                </IconButton>
              </Box>
            )}
            {retry.template.name !== "Current Template" && (
              <Box sx={{ display: "flex", width: "95%" }}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{
                    width: "185px",
                    marginTop: "20px",
                    marginRight: "20px",
                    textDecoration: "underline",
                  }}
                >
                  Retry {index}
                </Typography>
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Days
                  </Typography>
                  <TextField
                    value={retry.days}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        handleRetryChange(index, "days", value);
                      }
                    }}
                    sx={{
                      width: "60%",
                      "& .MuiInputBase-root": { height: "36px" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "black" },
                        "&:hover fieldset": { borderColor: "black" },
                        "&.Mui-focused fieldset": { borderColor: "black" },
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "80%",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Email Template
                  </Typography>
                  <Select
                    value={retry.template.name}
                    onChange={(e) =>
                      handleTemplateSelect(index, e.target.value)
                    }
                    sx={{
                      width: "80%",
                      height: "36px",
                      marginRight: "40px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "black" },
                        "&:hover fieldset": { borderColor: "black" },
                        "&.Mui-focused fieldset": { borderColor: "black" },
                      },
                    }}
                  >
                    <MenuItem value="Current Template">
                      <em>Current Template</em>
                    </MenuItem>
                    {emailTemplates.map((template, idx) => (
                      <MenuItem key={idx} value={template}>
                        {template}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box
                  sx={{
                    gap: 1,
                    width: 130,
                    flexShrink: 0,
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  {retries.length > 1 && (
                    <IconButton
                      onClick={() => handleRetryDelete(index)}
                      sx={{ color: "#d32f2f", marginLeft: "10px" }}
                    >
                      <Tooltip title="Delete retry">
                        <FontAwesomeIcon icon={faCircleMinus} />
                      </Tooltip>
                    </IconButton>
                  )}
                  <IconButton
                    onClick={() => handleGenerateRetry(index)}
                    sx={{ color: "#43a047" }}
                    disabled={!retry.days}
                  >
                    <Tooltip title="Generate retry template">
                      <FontAwesomeIcon icon={faRotateRight} />
                    </Tooltip>
                  </IconButton>
                  <IconButton
                    onClick={() => handleEyeButtonClick(index)}
                    sx={{ color: "#015d86" }}
                  >
                    <Tooltip
                      title={
                        visibleEmailBuilderIndex === index
                          ? "Hide template"
                          : "See template"
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          visibleEmailBuilderIndex === index
                            ? faEyeSlash
                            : faEye
                        }
                      />
                    </Tooltip>
                  </IconButton>
                </Box>
              </Box>
            )}
            {index === visibleEmailBuilderIndex && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  ml: 0,
                }}
              >
                <Typography variant="subtitle2" fontWeight="bold">
                  Retry Email Template
                </Typography>
                <Box
                  sx={{
                    height: "500px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                >
                  {retry.template.body ||
                  retry.template.name === "Current Template" ? (
                    <EmailBuilder
                      onEditorLoad={(editor) => handleEditorLoad(editor, index)}
                      exportHtml={(ref) =>
                        (retryEditorRefs.current[index] = ref)
                      }
                      variables={[]}
                      initialContent={
                        retryContents[index] ||
                        retry.template.body ||
                        (retry.template.name === "Current Template" ??
                          generalTabContent)
                      }
                      options={{
                        features: {
                          textEditor: {
                            default: true,
                            spellChecker: true,
                          },
                        },
                        appearance: {
                          theme: "white",
                        },
                        tools: {
                          text: {
                            enabled: true,
                            default: true,
                          },
                        },
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography color="text.secondary">
                        No template generated yet. Please enter days and click
                        Generate Email Retry Template.
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        ))}
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Button
            variant="contained"
            onClick={() => setOpenModal(true)}
            sx={{
              mt: 2,
              backgroundColor: "#015d86",
              "&:hover": { backgroundColor: "#014a6d" },
            }}
          >
            <Typography sx={{ fontSize: "13px" }}>Create New Retry</Typography>
          </Button>
        </Box>
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogTitle>Enter Days</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Days"
              type="number"
              fullWidth
              variant="outlined"
              value={daysInput}
              onChange={(e) => setDaysInput(e.target.value)}
            />
            <Typography variant="body2" color="text.secondary">
              Enter the number of days to wait before sending a new email.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)}>Cancel</Button>
            <Button
              onClick={() => handleAddRetryClick(daysInput, retries.length)}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message={snackbar.message}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: snackbar.isError ? "#d32f2f" : "#43a047",
            },
          }}
        />
      </Scrollbars>
    </Box>
  );
};

export default RetriesTab;
