import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { DndProvider } from "react-dnd";
import { GridLoader } from "react-spinners";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Tabs, Tab, Typography, Chip, Stack } from "@mui/material";
import {
  faDiagramProject,
  faGear,
  faRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import GeneralTab from "./GeneralTab";
import RetriesTab from "./RetriesTab";
import VariablesTab from "./VariablesTab";
import EmailBuilder from "../../../components/EmailBuilder/EmailBuilder";
import campaignServiceInstance from "../../../services/campaign/campaignService";
import { API } from "aws-amplify";
import { getCampaignEmailRetryTemplate } from "../../../graphql/queries";

const DraggableChip = ({ variable, onDrop }) => {
  const [{ isDragging }, drag] = useDrag({
    type: "VARIABLE",
    item: { value: variable.name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Chip
        label={variable.name}
        onClick={() => onDrop(variable.name)}
        sx={{
          backgroundColor: "#e3f2fd",
          "&:hover": {
            backgroundColor: "#90caf9",
          },
          cursor: "pointer",
        }}
      />
    </div>
  );
};

const EmailCampaignForm = ({
  senderName,
  onSenderNameChange,
  domain,
  onDomainChange,
  senderEmail,
  onSenderEmailChange,
  emailSubject,
  onEmailSubjectChange,
  isEditorLoading,
  isSending,
  selectedContacts,
  onEditorLoad,
  emailEditorRef,
  onSendCampaign,
  sampleDomainOptions,
  slideOverStyles,
  onSaveVariables,
  variableOptions,
  mappedFields,
  onSaveRetries,
}) => {
  const [htmlBody, setHtmlBody] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [emailContent, setEmailContent] = useState("");
  const [focusedField, setFocusedField] = useState(null);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [retries, setRetries] = useState([
    { days: "", template: { name: "Current Template" } },
  ]);
  const [variables, setVariables] = useState([{ name: "", value: "" }]);
  const [design, setDesign] = useState(null);
  const [generalTabContent, setGeneralTabContent] = useState("");
  const [isGeneralEditorReady, setIsGeneralEditorReady] = useState(false);

  useEffect(() => {
    if (onSaveVariables && typeof onSaveVariables === "function") {
      onSaveVariables(variables);
    }
  }, [variables, onSaveVariables]);

  const handleAddRetry = async (days, index) => {
    try {
      const newRetry = {
        days,
        template: {
          name: `Template ${retries.length}`,
          body: "",
          message: "",
        },
      };

      const updatedRetries = [...retries, newRetry];

      setRetries(updatedRetries);
      onSaveRetries(updatedRetries);
      setEmailTemplates((prev) => [...prev, `Template ${retries.length}`]);
    } catch (error) {
      console.error("Error creating retry:", error);
      throw error;
    }
  };

  const handleTabChange = (event, newValue) => {
    if (activeTab === 0 && emailEditorRef.current?.editor) {
      emailEditorRef.current.editor.exportHtml((data) => {
        console.log("Saving general tab content:", data.html);
        setGeneralTabContent(data.html);
      });
    }
    setActiveTab(newValue);
  };

  const updateFirstRetryWithHtmlBody = async () => {
    if (!window.unlayer || !emailEditorRef?.current?.editor) {
      console.error("Unlayer editor not available");
      return;
    }

    emailEditorRef.current.editor.exportHtml(async (data) => {
      const validatedHtml = campaignServiceInstance.validateEmailContent(
        data.html
      );
      const bodyContent = validatedHtml.match(/<body[^>]*>([\s\S]*?)<\/body>/i);
      const cleanBody = bodyContent
        ? bodyContent[1].replace(/\n/g, "").trim()
        : "";

      const updatedRetries = [...retries];
      updatedRetries[0] = {
        days: updatedRetries[0].days,
        template: {
          name: `Template 0`,
          body: validatedHtml,
          message: cleanBody,
        },
      };

      setRetries(updatedRetries);
      onSaveRetries(updatedRetries);
    });
  };

  const handleAddVariable = () => {
    setVariables([...variables, { name: "", value: "" }]);
  };

  const handleVariableChange = (newVariables) => {
    const validatedVariables = newVariables.map((variable) => ({
      name: variable.name.trim(),
      value: variable.value.trim(),
    }));

    setVariables(validatedVariables);
    if (onSaveVariables && typeof onSaveVariables === "function") {
      onSaveVariables(validatedVariables);
    }

    // Update email editor merge tags
    if (emailEditorRef.current?.editor) {
      const variableTags = {};
      validatedVariables.forEach((variable) => {
        if (variable.name && variable.value) {
          variableTags[variable.name] = {
            name: variable.name,
            value: `{{${variable.name}}}`,
            sample: variable.value,
          };
        }
      });
      emailEditorRef.current.editor.setMergeTags(variableTags);
    }
  };

  const handleDeleteVariable = (index) => {
    const newVariables = variables.filter((_, i) => i !== index);
    if (newVariables.length === 0) {
      setVariables([{ name: "", value: "" }]);
    } else {
      setVariables(newVariables);
    }
  };

  const handleChipClick = (variableValue) => {
    if (!focusedField) return;

    const value = `{{${variableValue}}}`;

    if (focusedField === "subject") {
      const cursorPosition =
        document.getElementById("email-subject").selectionStart;
      const currentValue = emailSubject;
      const newValue =
        currentValue.slice(0, cursorPosition) +
        value +
        currentValue.slice(cursorPosition);
      onEmailSubjectChange({ target: { value: newValue } });
    } else if (focusedField === "senderName") {
      const cursorPosition =
        document.getElementById("sender-name").selectionStart;
      const currentValue = senderName;
      const newValue =
        currentValue.slice(0, cursorPosition) +
        value +
        currentValue.slice(cursorPosition);
      onSenderNameChange({ target: { value: newValue } });
    }
  };

  const handleRetryChange = (index, field, value) => {
    console.log("Updating retry:", { index, field, value });

    const updatedRetries = retries.map((retry, i) => {
      if (i === index) {
        let updatedRetry = { ...retry };

        switch (field) {
          case "days":
            updatedRetry.days = value;
            break;
          case "template":
            updatedRetry.template = {
              name: value.name || value,
              body: value.body || retry.template?.body,
              message: value.message || retry.template?.message,
            };
            break;
          case "body":
            updatedRetry.template = {
              ...retry.template,
              body: value,
              message: value,
            };
            break;
          default:
            break;
        }
        return updatedRetry;
      }
      return retry;
    });

    console.log("Updated retries:", updatedRetries);

    setRetries(updatedRetries);
    onSaveRetries(updatedRetries);
  };

  const handleDeleteRetry = (index) => {
    console.log("Deleting retry at index:", index);

    const updatedRetries = retries.filter((_, i) => i !== index);

    if (updatedRetries.length === 0) {
      updatedRetries.push({
        days: "",
        template: {
          name: "Current Template",
          body: "",
          message: "",
        },
      });
    }

    setRetries(updatedRetries);
    onSaveRetries(updatedRetries);

    setEmailTemplates((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRetryBody = async (body) => {
    if (!window.unlayer || !emailEditorRef?.current?.editor) {
      console.error("Unlayer editor not available");
      return;
    }

    try {
      // Get the current design from the main editor
      const data = await new Promise((resolve) => {
        emailEditorRef.current.editor.exportHtml(resolve);
      });

      console.log("Current email template:", data.html);

      // Send both the original template and retry message to generate retry template
      const response = await API.graphql({
        query: getCampaignEmailRetryTemplate,
        variables: {
          originalTemplate: data.html,
          retryMessage: body,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      console.log("Retry template response:", response);
      const responseBody = JSON.parse(
        response.data.getCampaignEmailRetryTemplate.body
      );

      // Update the retries state with the new template
      if (responseBody.data) {
        const updatedRetries = [...retries];
        const lastRetry = updatedRetries[updatedRetries.length - 1];
        if (lastRetry) {
          lastRetry.template = {
            ...lastRetry.template,
            body: responseBody.data,
          };
        }
        setRetries(updatedRetries);
        onSaveRetries(updatedRetries);
      }

      return responseBody.data;
    } catch (error) {
      console.error("Error generating retry template:", error);
      throw error;
    }
  };

  const handleEditorContentChange = () => {
    if (emailEditorRef.current?.editor) {
      emailEditorRef.current.editor.saveDesign((design) => {
        setDesign(design);
        emailEditorRef.current.editor.exportHtml((data) => {
          setEmailContent(data.html);
          if (activeTab === 0) {
            setGeneralTabContent(data.html);
          }
        });
      });
    }
  };

  const handleEditorLoad = (editor) => {
    console.log("Editor loaded, activeTab:", activeTab);
    setIsGeneralEditorReady(true);

    if (activeTab === 0) {
      editor.addEventListener("design:updated", () => {
        editor.exportHtml((data) => {
          console.log("Updating general tab content:", data.html);
          setGeneralTabContent(data.html);
        });
      });

      // Load saved content if exists
      if (generalTabContent) {
        console.log("Loading saved general content");
        setTimeout(() => {
          editor.loadDesign({
            body: {
              rows: [
                {
                  cells: [1],
                  columns: [
                    {
                      contents: [
                        {
                          type: "text",
                          values: {
                            text: generalTabContent,
                            textAlign: "left",
                            lineHeight: "140%",
                            fontSize: "14px",
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          });
        }, 100);
      }
    }

    if (onEditorLoad) {
      onEditorLoad(editor);
    }
  };

  useEffect(() => {
    if (
      activeTab === 0 &&
      isGeneralEditorReady &&
      generalTabContent &&
      emailEditorRef.current?.editor
    ) {
      console.log("Loading general content after tab switch");
      emailEditorRef.current.editor.loadDesign({
        body: {
          rows: [
            {
              cells: [1],
              columns: [
                {
                  contents: [
                    {
                      type: "text",
                      values: {
                        text: generalTabContent,
                        textAlign: "left",
                        lineHeight: "140%",
                        fontSize: "14px",
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      });
    }
  }, [activeTab, isGeneralEditorReady, generalTabContent]);

  useEffect(() => {
    console.log("Retries state updated:", retries);
  }, [retries]);

  const handleSendCampaign = async () => {
    try {
      // Validate all required variables are defined
      const usedVariables = new Set();

      // Check subject for variables
      const subjectVariables = emailSubject.match(/\{\{([^}]+)\}\}/g) || [];
      subjectVariables.forEach((match) => {
        // Extract just the variable name without {{ }}
        const varName = match.replace(/[{}]/g, "").trim();
        usedVariables.add(varName);
      });

      // Check sender name for variables
      const senderNameVariables = senderName.match(/\{\{([^}]+)\}\}/g) || [];
      senderNameVariables.forEach((match) => {
        const varName = match.replace(/[{}]/g, "").trim();
        usedVariables.add(varName);
      });

      // Get email content and check for variables
      const emailContent = await emailEditorRef.current();
      const emailVariables = emailContent?.match(/\{\{([^}]+)\}\}/g) || [];
      emailVariables.forEach((match) => {
        const varName = match.replace(/[{}]/g, "").trim();
        usedVariables.add(varName);
      });

      // Check if all used variables are defined
      const definedVariables = new Set(variables.map((v) => v.name.trim()));
      const undefinedVariables = [...usedVariables].filter(
        (v) => !definedVariables.has(v)
      );

      if (undefinedVariables.length > 0) {
        throw new Error(
          `Undefined variables used: ${undefinedVariables.join(", ")}`
        );
      }

      // Validate retries
      const validatedRetries = retries
        .filter((retry) => retry.days && retry.template.body)
        .map((retry) => ({
          days: parseInt(retry.days, 10),
          template: {
            name: retry.template.name || "",
            body: retry.template.body || "",
            message: retry.template.message || retry.template.body || "",
          },
        }));

      await onSendCampaign(validatedRetries);
    } catch (error) {
      console.error("Campaign send failed:", error);
      throw error;
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ height: "100%", minHeight: "100%" }}
        >
          <Tab
            label="General"
            iconPosition="start"
            icon={
              <FontAwesomeIcon
                icon={faGear}
                style={{
                  fontSize: "14px",
                  marginBottom: "3px",
                  marginRight: "10px",
                }}
              />
            }
            sx={{
              minHeight: "40px",
              height: "40px",
            }}
          />
          <Tab
            label="Variables"
            iconPosition="start"
            icon={
              <FontAwesomeIcon
                icon={faDiagramProject}
                style={{
                  fontSize: "14px",
                  marginBottom: "3px",
                  marginRight: "10px",
                }}
              />
            }
            sx={{
              minHeight: "40px",
              height: "40px",
            }}
          />
          <Tab
            label="Retries"
            iconPosition="start"
            icon={
              <FontAwesomeIcon
                icon={faRotateRight}
                style={{
                  fontSize: "14px",
                  marginBottom: "3px",
                  marginRight: "10px",
                }}
              />
            }
            sx={{
              minHeight: "40px",
              height: "40px",
            }}
          />
        </Tabs>
      </Box>
      {activeTab === 0 ? (
        <GeneralTab
          senderName={senderName}
          onSenderNameChange={onSenderNameChange}
          domain={domain}
          onDomainChange={onDomainChange}
          senderEmail={senderEmail}
          onSenderEmailChange={onSenderEmailChange}
          emailSubject={emailSubject}
          onEmailSubjectChange={onEmailSubjectChange}
          isSending={isSending}
          sampleDomainOptions={sampleDomainOptions}
          onSenderNameFocus={() => setFocusedField("senderName")}
          onSenderNameBlur={() => setFocusedField(null)}
          onSubjectFocus={() => setFocusedField("subject")}
          onSubjectBlur={() => setFocusedField(null)}
        />
      ) : activeTab === 1 ? (
        <VariablesTab
          variables={variables}
          options={variableOptions}
          handleVariableChange={handleVariableChange}
          handleAddVariable={handleAddVariable}
          handleDeleteVariable={handleDeleteVariable}
          mappedFields={mappedFields}
        />
      ) : (
        <RetriesTab
          retries={retries}
          handleRetryChange={handleRetryChange}
          handleDeleteRetry={handleDeleteRetry}
          onAddRetry={handleAddRetry}
          emailTemplates={emailTemplates}
          generalTabContent={generalTabContent}
          isEditorLoading={isEditorLoading}
          variables={variables}
          onSaveRetries={(newRetries) => {
            setRetries(newRetries);
            onSaveRetries(newRetries);
          }}
        />
      )}
      {activeTab !== 2 && (
        <Box
          sx={{ p: 2, borderBottom: "1px solid rgba(0, 0, 0, 0.12)", mt: -2 }}
        >
          <Typography variant="subtitle2" fontWeight="bold" sx={{ mb: 1 }}>
            Available Variables
          </Typography>
          {variables[0].name !== "" ? (
            <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
              {variables.map((variable, index) => (
                <DraggableChip
                  key={index}
                  variable={variable}
                  onDrop={handleChipClick}
                />
              ))}
            </Stack>
          ) : (
            <Typography variant="body2" color="text.secondary">
              No variables have been created yet. Create them in the Variables
              tab.
            </Typography>
          )}
        </Box>
      )}
      {isEditorLoading && (
        <Box sx={slideOverStyles.loaderContainer}>
          <GridLoader />
        </Box>
      )}
      {activeTab !== 2 && (
        <EmailBuilder
          variables={variables}
          selectedContacts={selectedContacts}
          onEditorLoad={handleEditorLoad}
          exportHtml={emailEditorRef}
          initialContent={activeTab === 0 ? generalTabContent : emailContent}
        />
      )}
      {activeTab === 0 && (
        <Box sx={{ position: "relative", mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleSendCampaign}
            disabled={isSending || !emailSubject.trim()}
            sx={{
              position: "absolute",
              bottom: 16,
              right: 16,
              backgroundColor: "#015d86",
              "&:hover": { backgroundColor: "#014a6d" },
            }}
          >
            <Typography sx={{ fontSize: "13px" }}>
              {isSending ? "Sending..." : "Send Campaign"}
            </Typography>
          </Button>
        </Box>
      )}
    </DndProvider>
  );
};

export default EmailCampaignForm;
