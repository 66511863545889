import React, {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {Box} from "@mui/material";
import {Scrollbars} from "react-custom-scrollbars-2";
import {deleteInterview, getInterview, putInterview,} from "../../graphql/queries";
import QuestionsList from "./QuestionsList";
import QuestionsBuilder from "./QuestionBuilder";
import UseNotification from "../../utils/UseNotification";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import NotificationDialog from "../../components/NotificationDialog/NotificationDialog";

const Interviews = () => {
    const [view, setView] = useState("list");
    const [groupName, setGroupName] = useState("");
    const [questions, setQuestions] = useState([]);
    const [questionGroups, setQuestionGroups] = useState([]);
    const [interviewToUpdate, setInterviewToUpdate] = useState(null);
    const [currentInterviewId, setCurrentInterviewId] = useState(null);
    const [customerFromClaim, setCustomer] = useState(false);
    const [deleteQuestionDialogOpen, setDeleteQuestionDialogOpen] =
        useState(false);
    const [deleteInterviewDialogOpen, setDeleteInterviewDialogOpen] =
        useState(false);

    const {
        openNotification,
        notificationContent,
        handleOpenNotification,
        handleCloseNotification,
    } = UseNotification();

    useEffect(() => {

        const checkCustomer = async () => {
            try {
                const userInfo = await Auth.currentAuthenticatedUser();
                const customer = userInfo.attributes["custom:account"];
                setCustomer(customer);
                fetchQuestions(customer);
            } catch (e) {
                console.log("No current user", e);
            }
        };

        checkCustomer();
    }, [customerFromClaim]);

    const useConfirmationDialog = () => {
        const handleInterviewOpenConfirm = () => {
            setDeleteInterviewDialogOpen(true);
        };

        const handleQuestionOpenConfirm = () => {
            setDeleteQuestionDialogOpen(true);
        };

        const handleInterviewCloseConfirm = () => {
            setDeleteInterviewDialogOpen(false);
        };

        const handleQuestionCloseConfirm = () => {
            setDeleteQuestionDialogOpen(false);
        };

        return {
            handleInterviewOpenConfirm,
            handleQuestionOpenConfirm,
            handleInterviewCloseConfirm,
            handleQuestionCloseConfirm,
        };
    };

    const {
        handleInterviewOpenConfirm,
        handleQuestionOpenConfirm,
        handleInterviewCloseConfirm,
        handleQuestionCloseConfirm,
    } = useConfirmationDialog();

    const fetchQuestions = async (customer) => {
        if (!customer) return
        const params = {customer: customer, interview: ""};
        try {
            const response = await API.graphql({
                query: getInterview,
                variables: params,
                authMode: "AMAZON_COGNITO_USER_POOLS",
            });
            const body = JSON.parse(response.data.getInterview.body);
            console.log("body: ", body);
            setQuestionGroups(body?.data?.items);
        } catch (error) {
            throw new Error("Error fetching questions");
        }
    };

    const putQuestions = async (questions) => {
        handleOpenNotification("Saving interview", "", "loading");
        const params = {
            customer: customerFromClaim,
            name: groupName,
            description: "",
            questions: questions,
            id: currentInterviewId ?? "",
        };
        try {
            const response = await API.graphql({
                query: putInterview,
                variables: params,
                authMode: "AMAZON_COGNITO_USER_POOLS",
            });
            const body = JSON.parse(response.data.putInterview.body);
            const statusCode = response.data.putInterview.statusCode;
            console.log("body: ", body);
            if (statusCode !== "200") {
                handleOpenNotification(
                    "Error",
                    `${body.message}` ?? "An error occurred, try again.",
                    "error"
                );
            }
            handleOpenNotification("Success", body.message, "success");
            setView("list");
        } catch (error) {
            handleOpenNotification("Error", `${error}`, "error");
            throw new Error("Error creating/updating questions");
        } finally {
            fetchQuestions(customerFromClaim);
            setCurrentInterviewId("");
            setInterviewToUpdate(null);
            handleQuestionCloseConfirm();
        }
    };

    const deleteQuestions = async () => {
        const params = {interview: currentInterviewId};
        handleOpenNotification("Deleting interview", "", "loading");

        try {
            const response = await API.graphql({
                query: deleteInterview,
                variables: params,
                authMode: "AMAZON_COGNITO_USER_POOLS",
            });
            const body = JSON.parse(response.data.deleteInterview.body);
            const statusCode = response.data.deleteInterview.statusCode;
            console.log("body: ", body);
            if (statusCode !== "200") {
                handleOpenNotification(
                    "Error",
                    `${body.message}` ?? "An error occurred, try again.",
                    "error"
                );
            }
            handleOpenNotification("Success", body.message, "success");
        } catch (error) {
            handleOpenNotification("Error", `${error}`, "error");
            throw new Error("Error creating/updating questions");
        } finally {
            fetchQuestions(customerFromClaim);
            handleQuestionCloseConfirm();
            setDeleteInterviewDialogOpen(false);
        }
    };

    const handleAddQuestions = () => {
        setGroupName("");
        setQuestions(["", "", ""]);
        setView("builder");
        setCurrentInterviewId("");
    };

    const handleEditQuestions = (group) => {
        setGroupName(group.name);
        setQuestions(group.questions);
        setView("builder");
        setCurrentInterviewId(group.id);
    };

    const handleBackToList = () => {
        setView("list");
    };

    const handleAddQuestionField = () => {
        setQuestions([...questions, ""]);
    };

    const handleDeleteQuestion = (index) => {
        if (questions.length > 1) {
            const updatedQuestions = questions.filter((_, i) => i !== index);
            setQuestions(updatedQuestions);
        }
    };

    const handleDeleteInterview = (interviewId) => {
        setCurrentInterviewId(interviewId);
        handleInterviewOpenConfirm();
    };

    const handleDeleteSingleQuestion = (interview, index) => {
        const updatedQuestions = interview.questions.filter((_, i) => i !== index);
        setCurrentInterviewId(interview.id);
        setInterviewToUpdate({
            ...interview,
            questions: updatedQuestions,
        });
        handleQuestionOpenConfirm();
    };

    const handleConfirmDeleteQuestion = () => {
        putQuestions(interviewToUpdate.questions);
    };

    return (
        <Box sx={{height: "100%"}}>
            <Scrollbars style={{height: "100%"}}>
                {view === "list" && (
                    <QuestionsList
                        questionGroups={questionGroups}
                        onAdd={handleAddQuestions}
                        onEdit={handleEditQuestions}
                        onDeleteInterview={handleDeleteInterview}
                        onDeleteQuestion={handleDeleteSingleQuestion}
                    />
                )}
                {view === "builder" && (
                    <QuestionsBuilder
                        currentInterview={currentInterviewId}
                        initialGroupName={groupName}
                        questions={questions}
                        onAddQuestionField={handleAddQuestionField}
                        onBack={handleBackToList}
                        handleQuestionChange={(index, value) => {
                            const updatedQuestions = [...questions];
                            updatedQuestions[index] = value;
                            setQuestions(updatedQuestions);
                        }}
                        handleNameChange={(value) => {
                            setGroupName(value);
                        }}
                        handleDeleteQuestion={handleDeleteQuestion}
                        onSave={putQuestions}
                    />
                )}
                <ConfirmationDialog
                    open={deleteInterviewDialogOpen}
                    onClose={handleInterviewCloseConfirm}
                    onConfirm={deleteQuestions}
                    onReject={handleInterviewCloseConfirm}
                    title={"Confirm Interview Deletion"}
                    message={"Are you sure you want to delete this questions group?"}
                />
                <ConfirmationDialog
                    open={deleteQuestionDialogOpen}
                    onClose={handleQuestionCloseConfirm}
                    onConfirm={handleConfirmDeleteQuestion}
                    onReject={handleQuestionCloseConfirm}
                    title={"Confirm Question Deletion"}
                    message={"Are you sure you want to delete this question?"}
                />
                <NotificationDialog
                    open={openNotification}
                    onClose={handleCloseNotification}
                    title={notificationContent.title}
                    message={notificationContent.message}
                    type={notificationContent.type}
                />
            </Scrollbars>
        </Box>
    );
};

export default Interviews;
