import _ from "lodash";
import { API, Auth, Storage } from "aws-amplify";
import { putCampaignEmail, putCampaignSms } from "../../graphql/queries";

const ENV = _.isUndefined(process.env.REACT_APP_USER_BRANCH)
  ? "dev"
  : process.env.REACT_APP_USER_BRANCH;
const REGION = _.isUndefined(process.env.REACT_APP_REGION)
  ? "us-east-1"
  : process.env.REACT_APP_REGION;
const APP_LEVEL = _.isUndefined(process.env.REACT_APP_LEVEL)
  ? "internship"
  : process.env.REACT_APP_LEVEL;

class CampaignService {
  validateEmailContent(emailHtml) {
    if (!emailHtml || emailHtml.trim().length === 0) {
      throw new Error("Email content cannot be empty");
    }
    return emailHtml;
  }

  isValidEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  validateAndExtractEmails(contacts) {
    if (!contacts || contacts.length === 0) {
      throw new Error("Please select at least one contact");
    }

    const emailAddresses = contacts
      .map((contact) => {
        const email = contact?.email?.toLowerCase().trim();
        if (!email || !this.isValidEmail(email)) {
          console.warn("Invalid email found:", { contact, email });
          return null;
        }
        return email;
      })
      .filter((email) => email !== null);

    if (emailAddresses.length === 0) {
      throw new Error("No valid email addresses found in selected contacts");
    }

    return emailAddresses;
  }

  async uploadFile(file, campaignId) {
    const amplifyConfig = Auth.configure();

    if (amplifyConfig && Object.keys(amplifyConfig).length > 0) {
      const identityPoolId = amplifyConfig.aws_cognito_identity_pool_id;
      console.log("identityPoolId: ", identityPoolId);

      Storage.configure({
        region: REGION,
        bucket: `${ENV}-cai3p0-customer`,
        identityPoolId: identityPoolId,
        level: APP_LEVEL,
      });
    }

    const filePath = `cai3p0/campaign/${campaignId}/contacts/email/${file.name}`;
    const result = await Storage.put(filePath, file, {
      contentType: file.type,
    });
    console.log(`File uploaded: ${JSON.stringify(result)}`);
    return filePath;
  }

  async sendEmailCampaign(data) {
    const tableSelectionPayload = {
      destination: data.destination,
      source: data.source,
      source_name: data.source_name,
      subject: data.subject,
      text_body: data.text_body,
      id: data.id,
      type: data.type,
      name: data.name,
      status: data.status,
      totalRecipients: data.totalRecipients,
      metadata: data.metadata,
      channels: data.channels,
      html_body: data.html_body,
      contact_file: JSON.stringify({}),
      contact_headers_map: JSON.stringify({}),
      retries: data.retries,
    };

    console.log(
      "📨 Table Selection Payload:",
      JSON.stringify(tableSelectionPayload, null, 2)
    );
    console.log(
      "📨 Raw email campaign payload:",
      JSON.stringify(data, null, 2)
    );
    console.log("📨 Destination type:", typeof data.destination);
    console.log("📨 Destination value:", data.destination);
    console.log("📨 Is destination array?", Array.isArray(data.destination));
    console.log("📨 Contact file:", data.contact_file);
    console.log("📨 Headers map:", data.contact_headers_map);

    try {
      const response = await API.graphql({
        query: putCampaignEmail,
        variables: data,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      console.log("📬 API Response:", response);

      if (response.errors) {
        console.error("❌ Email sending failed:", {
          errors: response.errors,
          campaignId: data.id,
        });
        throw new Error(response.errors[0]?.message || "Failed to send email");
      }

      console.log("✅ Email campaign sent successfully:", {
        campaignId: data.id,
        response: response.data,
        recipientCount: data.totalRecipients,
      });

      return response;
    } catch (error) {
      console.error("❌ Detailed email sending error:", {
        error: error.message,
        errorDetails: error.errors || error,
        payload: {
          destination: data.destination,
          destinationType: typeof data.destination,
          isArray: Array.isArray(data.destination),
          sampleData: Array.isArray(data.destination)
            ? data.destination.slice(0, 3)
            : null,
          contactFile: data.contact_file,
          headersMap: data.contact_headers_map,
        },
      });
      throw error;
    }
  }

  async createAndSendCampaign({
    agentId,
    campaignId,
    emailSubject,
    emailHtml,
    selectedContacts,
    senderEmail,
    senderName,
    contactsFile,
    mappedFields,
    selectionMode,
    retries,
  }) {
    console.log("🚀 Starting campaign creation process...", {
      campaignId,
      emailSubject,
      selectionMode,
      selectedContactsCount: selectedContacts?.length,
      hasContactFile: !!contactsFile,
      hasMappedFields: !!mappedFields,
      timestamp: new Date().toISOString(),
    });

    // Validate email content
    const validatedHtml = this.validateEmailContent(emailHtml);
    console.log("✅ Email content validated");

    const retriesPayload = retries.slice(1).map((retry) => ({
      days: retry.days,
      template: retry.template.body,
    }));

    const retriesArray = retriesPayload.map((retry) => JSON.stringify(retry));

    let data = {
      source: senderEmail,
      source_name: senderName,
      subject: emailSubject.trim(),
      text_body: "",
      id: campaignId,
      type: "email",
      name: emailSubject,
      status: "active",
      metadata: JSON.stringify({
        ai_agent: agentId,
        selection_mode: selectionMode,
      }),
      channels: JSON.stringify({}),
      html_body: validatedHtml,
      destination: [],
      contact_file: JSON.stringify({}),
      contact_headers_map: JSON.stringify({}),
      protected_intents: JSON.stringify({}),
      retries: retriesArray,
    };

    if (selectionMode === "table") {
      // For table selection, validate emails and include destination
      console.log("📋 Processing table selection mode...");

      // Ensure emailAddresses is an array

      const destinationsArray = selectedContacts.map((contact) =>
        JSON.stringify(contact)
      );

      data = {
        ...data,
        destination: destinationsArray,
        totalRecipients: selectedContacts.length,
        retries: retriesArray,
      };

      console.log("✅ Table selection processed:", {
        destinationIsArray: Array.isArray(data.destination),
        destinationLength: data.destination.length,
      });
    } else if (selectionMode === "import") {
      // For CSV import, keep destination as empty array
      console.log("📁 Processing CSV import mode...");
      const filePath = await this.uploadFile(contactsFile, campaignId);
      data = {
        ...data,
        contact_file: JSON.stringify({
          contact_path_s3: filePath,
        }),
        contact_headers_map: JSON.stringify({ mappedFields }),
        totalRecipients: selectedContacts.length,
        // destination remains empty array
      };

      console.log("✅ CSV import processed:", {
        filePath,
        totalContacts: selectedContacts.length,
        destinationIsArray: Array.isArray(data.destination),
        destinationLength: data.destination.length,
        mappedFields: Object.keys(mappedFields),
      });
    }

    // Final validation to ensure destination is always an array
    if (!Array.isArray(data.destination)) {
      console.warn("⚠️ Destination was not an array, converting...");
      data.destination = Array.isArray(data.destination)
        ? data.destination
        : [];
    }

    console.log("📦 Final campaign data structure:", {
      destinationIsArray: Array.isArray(data.destination),
      destinationLength: data.destination.length,
      hasContactFile: !!data.contact_file,
      hasMappedFields: !!data.contact_headers_map,
      totalRecipients: data.totalRecipients,
      metadata: JSON.parse(data.metadata),
    });

    const sendResult = await this.sendEmailCampaign(data);

    console.log("🎉 Campaign process completed:", {
      campaignId,
      success: true,
      recipientCount: data.totalRecipients,
      selectionMode,
      destinationIsArray: Array.isArray(data.destination),
      destinationLength: data.destination.length,
      timestamp: new Date().toISOString(),
    });

    return {
      success: true,
      recipientCount: data.totalRecipients,
      campaignId,
      response: sendResult.data,
    };
  }

  async createSMSCampaign(campaignData) {
    try {
      const data = {
        id: campaignData.id,
        name: campaignData.name,
        phoneNumber: "",
        source: campaignData.phoneNumber,
        type: "sms",
        status: "active",
        metadata: JSON.stringify({
          ai_agent: campaignData.agentId,
          campaign_type: "inbound",
          created_at: new Date().toISOString(),
        }),
        channels: JSON.stringify({ sms: true }),
        contact_file: JSON.stringify({}),
        totalRecipients: 0, // Zero as it's inbound
      };

      console.log("📦 SMS Campaign data structured:", {
        campaignId: data.id,
        campaignName: data.name,
        phoneNumber: data.phoneNumber,
        agentId: campaignData.agentId,
        type: data.type,
        status: data.status,
        timestamp: new Date().toISOString(),
      });

      const response = await API.graphql({
        query: putCampaignSms,
        variables: data,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      console.log("✅ SMS Campaign created successfully:", {
        campaignId: data.id,
        response: response.data.putCampaignSms,
        timestamp: new Date().toISOString(),
      });

      return response.data.putCampaignSms;
    } catch (error) {
      console.error("❌ Error creating SMS campaign:", {
        campaignId: campaignData.id,
        error: error.message,
        details: error.errors || error,
      });
      throw new Error(error.message || "Failed to create SMS campaign");
    }
  }
}

const campaignServiceInstance = new CampaignService();
export default campaignServiceInstance;
