import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const PhoneListManager = ({
  phones = [],
  onPhoneListChange,
  type = "phone",
}) => {
  const [phone, setPhone] = useState("");
  const [phoneList, setPhoneList] = useState(phones);

  useEffect(() => {
    setPhoneList(phones);
  }, [phones]);

  const handleAddPhone = () => {
    if (phone.trim() && isValidPhone(phone)) {
      const updatedList = [...phoneList, phone];
      setPhoneList(updatedList);
      setPhone(""); // Clear the input field
      onPhoneListChange(updatedList);
    } else {
      alert("Please enter a valid phone number.");
    }
  };

  const handleRemovePhone = (indexToRemove) => {
    const updatedList = phoneList.filter((_, index) => index !== indexToRemove);
    setPhoneList(updatedList);
    onPhoneListChange(updatedList);
  };

  const isValidPhone = (string) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    return phoneRegex.test(string);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
        <TextField
          fullWidth
          variant="outlined"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder={`Enter ${type} number`}
          sx={{
            "& .MuiInputBase-root": { height: "36px" },
            "& .MuiOutlinedInput-root": {
              "& fieldset": { borderColor: "black" },
              "&:hover fieldset": { borderColor: "black" },
              "&.Mui-focused fieldset": { borderColor: "black" },
            },
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleAddPhone();
            }
          }}
        />
        <IconButton
          onClick={handleAddPhone}
          sx={{
            height: 36,
            width: 36,
            "&:hover": { color: "#015d86" },
          }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
      <List>
        {phoneList.map((phone, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleRemovePhone(index)}
                sx={{ "&:hover": { color: "red" } }}
              >
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText primary={phone} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default PhoneListManager;
